import { map } from 'lodash'
import { useEffect } from 'react'

const LINKS = [{
  rel: 'stylesheet',
  url: 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css',
  type: 'text/css'
}, {
  rel: 'stylesheet',
  url: 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.min.css',
  type: 'text/css'
}, {
  rel: 'stylesheet',
  url: 'https://unpkg.com/placeholder-loading/dist/css/placeholder-loading.min.css',
  type: 'text/css'
}]

const Layout = ({ children }) => {
  useEffect(() => {
    const head = document.head

    map(LINKS, ({ url, type, rel }) => {
      const link = document.createElement('link')

      link.rel = rel
      link.href = url
      link.type = type

      head.append(link)
    })
  }, [])

  return children
}

export default Layout
